export const Configs = {
    BASE_API: process.env.REACT_APP_BASE_API_URL,
    DOMAIN: '',

    CURRENT_PAGE: 1,
    FILE_MAXIMUM: 5, //MB
    PAGE_SIZE_20: 20,
    PAGE_SIZE_4: 4,
};

export const REQUEST_STATE = {
    ERROR: 'ERROR',
    REQUEST: 'REQUEST',
    SUCCESS: 'SUCCESS',
};

// key store in localStorage, Cookies, Session
export const I18LANGUAGE_KEY = 'i18nextLng';
export const TOKEN_KEY = 'authencation_COBGBRPIIP';
export const SIDER_COLLAPSE = 'siderCollapse';

export const ACTION_TYPE = {
    CREATE: 'CREATE',
    LIST: 'LIST',
    VIEW: 'VIEW',
    DELETE: 'DELETE',
    UPDATE: 'UPDATE',
    UNMOUNT: 'UNMOUNT',
};

export const VALID_IMAGE_TYPES = ['image/gif', 'image/jpeg', 'image/png'];

// 1. UI flow: add router
export const USER_ROLES = {
    UPLOAD: 1,
    SIGN: 2,
    UPLOAD_TO_BLOCKCHAIN: 3,
};

export const ACCOUNT_PERMISSION = {
    UPLOAD: {
        value: 'uploader',
        text: 'Tải tài liệu',
        color: '#828FD1',
    },
    SIGN: {
        value: 'signer',
        text: 'Ký',
        color: '#087AB6',
    },
    UPLOAD_TO_BLOCKCHAIN: {
        value: 'publisher',
        text: 'Tải lên blockchain',
        color: '#49C2C6',
    },
};

export const TEMPLATE_PERMISSION = {
    upload: {
        value: 'upload',
        text: 'Tải tài liệu',
        color: '#828FD1',
    },
    sign: {
        value: 'signer',
        text: 'Ký',
        color: '#087AB6',
    },
    publish: {
        value: 'publisher',
        text: 'Tải lên blockchain',
        color: '#49C2C6',
    },
};

export const STUDENT_CEFT_STATUS = {
    DATA_UPLOAD: '0 - DATA_UPLOAD',
    PENDING: '2 - PENDING',
    NEED_SIGN: '3 - NEED_SIGN',
    COMPLETE_SIGN: '4 - COMPLETE_SIGN',
    PDF_GENERATED: '1 - PDF_GENERATED',
    UPLOAD_TO_BC: '6 - UPLOAD_TO_BC',
    SEND_EMAIL: '8 - SEND_EMAIL',
    ERROR_SOME_FILE_SEND_EMAIL: '7 - ERROR_SOME_FILE_SEND_EMAIL',
    WRAP: '5 - WRAP',
    REVOKED: '7 - REVOKE',
};

// 1. UI flow: add router
export const CERT_STATUS = [
    {
        text: 'Chưa ký xong',
        value: [STUDENT_CEFT_STATUS.NEED_SIGN, STUDENT_CEFT_STATUS.PENDING],
    },
    {
        text: 'Đã ký xong',
        value: STUDENT_CEFT_STATUS.COMPLETE_SIGN,
    },
    {
        text: 'Đã tải lên blockchain',
        value: STUDENT_CEFT_STATUS.UPLOAD_TO_BC,
    },
    {
        text: 'Đã gửi email',
        value: STUDENT_CEFT_STATUS.SEND_EMAIL,
    },
];

export const EXPIRED_TIME_CONTRACT = 1672531200;

export const ADDRESS_OWNER_MANAGEMENT = '0xfBb4F8AFa108B4A3B1AD2C80aD77045611f5be86';
