import { TOKEN_KEY } from 'app-configs';
import { REQUEST_STATE } from 'app-configs';
import { PUT } from 'app-data/fetch';
import { DELETE } from 'app-data/fetch';
import { POST } from 'app-data/fetch';
import { GET } from 'app-data/fetch';

export const apiListIssuer = async (params) => {
    try {
        const response = await GET('/issuer/list/institution', params, { isFullPath: false });
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response?.data ?? [],
            total: response?.total ?? 0,
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};

export const apiCreateIssuer = async (params) => {
    try {
        const response = await POST('/issuer/create', params, { isFullPath: false });
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response,
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};

export const apiDeleteIssuerAccount = async (params) => {
    try {
        const response = await DELETE('/issuer', params, { isFullPath: false });
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response,
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};

export const apiActiveIssuerAccount = async (params) => {
    try {
        const response = await PUT('/issuer/activate', params, { isFullPath: false });
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response,
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};

export const apiDeActiveIssuerAccount = async (params) => {
    try {
        const response = await PUT('/issuer/deActivate', params, { isFullPath: false });
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response,
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};

export const apiFilterIssuer = async (params) => {
    try {
        const response = await GET('/issuer/list/filter', params, { isFullPath: false });
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response?.data ?? [],
            total: response?.total ?? [],
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};

export const apiUpdateInstitution = async (params) => {
    try {
        const response = await PUT('/institution', params, { isFullPath: false });
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response,
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};

export const apiUploadAvatarIssuer = async (params) => {
    try {
        const response = await POST('/issuer/upload', params, { isFullPath: false });
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response,
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};

export const apiUpdateIssuerByAdmin = async (params) => {
    console.log('params: ', params);
    try {
        const response = await PUT('/issuer/admin/update-info', params, { isFullPath: false });
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response,
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};
