import { lazy } from 'react';
import { initModules } from 'router/index';

export const configSignModule = {
    key: 'configSign',
    path: 'ConfigSign',
};

export default {
    path: '/config/select-ceft',
    exact: true,
    isPrivate: true,
    component: lazy(async () => {
        await initModules([configSignModule], 'app');
        return import('./pages/SelectCeft');
    }),
};

export const childRoutes = [
    {
        path: '/config/sign-ceft/:id',
        exact: true,
        isPrivate: true,
        component: lazy(async () => {
            await initModules([configSignModule], 'app');
            return import('./pages/ConfigSign');
        }),
    },
];
